import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { DynamicButtonStatus, colors } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import paths from '../../routing/paths';
import { CartStep } from '../types';
import { setShowFeedback } from '../actions';
import { zIndex, heights, durations, opacities } from '../../common/constants';
import { getSizeLabel, isItGiftCard } from '../../product/utils';
import usePrevious from '../../common/hooks/usePrevious';
import { useDevice } from '../../common/hooks/useDevice';
import { loadProductRecommendations } from '../../recommendations/actions';
import { TGiftCardInfos } from '../../product/components/Product';
import { FeedbackModal } from '../../quickadd/components/FeedbackModal';
import { closeModalNew, openModalNew } from '../../common/actions';

const StyledBackground = styled.button<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.BLACK};
  border: none;
  padding: 0;
  z-index: ${zIndex.PRODUCT_SELECTOR_MODAL};
  transition: opacity ${durations.ENTER}ms ease-in-out;
  opacity: ${(props) => (props.show ? opacities.BACKGROUND : 0)};
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

const StyledContent = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: ${(props) => (props.show ? `${heights.CART_FEEDBACK_MOBILE}px` : '0')};
  overflow: hidden;
  background-color: ${colors.WHITE};
  z-index: ${zIndex.MODAL};
  transition: max-height ${durations.ENTER}ms ease-in-out;
`;

type Props = {
  onClose?: () => void;
  setProductQuantityAdded?: (value: number) => void;
  giftCardInfos?: TGiftCardInfos;
  quantity?: number;
};

export const Feedback = ({ quantity = 1, onClose, setProductQuantityAdded }: Props) => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedProduct = useSelector((state: RootState) => state.product.productModal);
  const selectedProductSize = useSelector((state: RootState) => state.product.sizeModal);

  const {
    productRef,
    colorRef,
    detailedColorLabel,
    colorLabel,
    productName,
    collectionName,
    storePrice,
    originalPrice,
    promotionLabel,
    promotionPercentage,
    promoBColor,
    promoTColor,
    isOutlet,
    gcFields,
  } = selectedProduct ?? {};

  const { email, date, price: priceGiftCard } = gcFields ?? {};
  const { showFeedback, ctaState, step } = useSelector((state: RootState) => state.cart);

  const closeFeedback = () => {
    dispatch(setShowFeedback(false));

    if (onClose) {
      onClose();
    }
  };

  const displaySuccessModal = () => {
    dispatch(
      openModalNew({
        content: feedBackComponent,
        preset: isGiftCard ? undefined : 'large',
      })
    );
  };

  const prevCtaState = usePrevious(ctaState);
  useEffect(() => {
    if (prevCtaState && ctaState !== prevCtaState && ctaState === DynamicButtonStatus.Success) {
      if (isMobile) {
        dispatch(setShowFeedback(true));
      } else {
        dispatch(closeModalNew());
        !isGiftCard &&
          dispatch(
            loadProductRecommendations({
              productRef,
              colorRef,
            }) as unknown as UnknownAction
          );
        setTimeout(displaySuccessModal, 300);
      }
    }
  }, [ctaState]);

  useEffect(() => {
    if (step > CartStep.LISTING) {
      navigate(paths.CART);
    }
  }, [step]);

  useEffect(() => {
    if (showFeedback) {
      closeFeedback();
    }
  }, [pathname]);

  useEffect(() => {
    if (showFeedback) {
      dispatch(
        loadProductRecommendations({
          productRef,
          colorRef,
        }) as unknown as UnknownAction
      );
    }
  }, [showFeedback]);

  const isGiftCard = isItGiftCard(productRef);

  const feedBackComponent = (
    <FeedbackModal
      productRef={productRef}
      colorRef={colorRef}
      colorLabel={detailedColorLabel ?? colorLabel}
      productName={productName}
      storePrice={storePrice}
      originalPrice={originalPrice}
      size={getSizeLabel(selectedProductSize)}
      collectionName={collectionName}
      quantity={quantity}
      setProductQuantityAdded={setProductQuantityAdded}
      onClose={onClose}
      promotionLabel={promotionLabel}
      promotionPercentage={promotionPercentage}
      promotionBackgroundColor={promoBColor}
      promotionTextColor={promoTColor}
      isOutlet={isOutlet}
      objectID={`${productRef}/${colorRef}`}
      email={email}
      date={date}
      priceGiftCard={priceGiftCard}
      isGiftCard={isGiftCard}
    />
  );

  return (
    <>
      <StyledBackground
        type="button"
        onClick={closeFeedback}
        data-testid="feed-back-background-button-to-close-modal"
        show={showFeedback}
      />
      {showFeedback && <StyledContent show={showFeedback}>{feedBackComponent}</StyledContent>}
    </>
  );
};
