import { useEffect } from 'react';
import { useClientAsyncInit } from '@statsig/react-bindings';

import { STATSIG_CLIENT_API } from '../../api/constants';

type Props = {
  cartId: string;
};

export const useAsyncStatsigClient = ({ cartId }: Props) => {
  const { client, isLoading } = useClientAsyncInit(
    STATSIG_CLIENT_API,
    {
      userID: cartId,
    },
    { environment: { tier: process.env.DEPLOYMENT_ENV } }
  );

  useEffect(() => {
    client.updateUserAsync({ userID: cartId });
  }, [cartId]);

  return { client, isLoading };
};
