import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { Size, Color } from '../types';
import {
  sortSizes,
  getIsSizeAvailable,
  getSizeMessage,
  getColorImageLink,
  getSizeLabel,
  TGiftCardErrors,
  sizeNoLongerAvailable,
} from '../utils';
import Sizes from './Sizes';
import { Colors } from './Colors';
import { changeSize, setSizeErrMsg } from '../actions';
import { emptySize } from '../state';
import { useDevice } from '../../common/hooks/useDevice';
import {
  Box,
  Text,
  Icon,
  DynamicButton,
  DynamicButtonStatus,
  colors,
} from '../../../design-system';
import { LBL_COLOR, LBL_SIZE, CTA_CONTENT, LBL_AMOUNT } from '../locale';
import { zIndex, opacities, durations, transitions, heights } from '../../common/constants';
import useScrollLock from '../../common/hooks/useScrollLock';
import { QuantitySelector } from './QuantitySelector';
import { transitionStylesBackground } from '../../common/components/Sidebar';
import { GiftCardCustomInfos } from './GiftCardCustomInfos';
import AmountSelector from './AmountSelector';
import { TGiftCardInfos } from './Product';
import { Status } from '../../../design-system/button/components/DynamicButton';
import { getIsClient } from '../../common/utils';
import { getIsColorFilterWhitish } from '../../filters/utils';

const transitionStylesContent = {
  entering: { opacity: 0, maxHeight: 0 },
  entered: { opacity: 1, maxHeight: `${heights.PRODUCT_SELECTOR_MOBILE}px` },
  exiting: { opacity: 0, maxHeight: 0 },
  exited: { opacity: 0, maxHeight: 0 },
};

const StyledBackground = styled.div<{ customSizeBlockForVariantTwoIsOpen: boolean }>`
  visibility: ${(props) => (props.customSizeBlockForVariantTwoIsOpen ? 'visible' : 'hidden')};
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.BLACK};
  left: 0;
  opacity: ${(props) => (props.customSizeBlockForVariantTwoIsOpen ? 0.3 : 0)};
  z-index: ${zIndex.PRODUCT_SELECTOR_MODAL - 1};
  transition: opacity ${durations.ENTER}ms ease-in-out;
`;

type Props = {
  size: Size;
  sizeErrMsg: string;
  sizeVariants: Size[];
  colorRef: string;
  detailedColorLabel: string;
  colorVariants: Color[];
  productRef: string;
  pathname: string;
  ctaState: DynamicButtonStatus;
  status: boolean;
  realColorRef: string;
  quantity: number;
  objectID: string;
  onCtaClick: () => void;
  isGiftCard: boolean;
  onGiftCardInfo: any;
  handleNewAmount: (amount: string) => void;
  isCustomAmountDeselected: boolean;
  giftCardErrMsg: TGiftCardErrors;
  giftCardAmount: string;
  giftCardInfos: TGiftCardInfos;
  isQuickAddOnPDP: boolean;
  quantityAlreadyAddedOnCart: number;
  colorLabel: string;
  handleCustomSizeBlockToggle: (value: boolean) => void;
  customSizeBlockForVariantTwoIsOpen: boolean;
};

export const Selectors = ({
  size,
  sizeErrMsg,
  sizeVariants,
  colorRef,
  productRef,
  detailedColorLabel,
  colorVariants,
  objectID,
  pathname,
  ctaState,
  status,
  quantity,
  onCtaClick,
  isGiftCard,
  onGiftCardInfo,
  realColorRef,
  handleNewAmount,
  isCustomAmountDeselected,
  giftCardErrMsg,
  giftCardAmount,
  giftCardInfos,
  isQuickAddOnPDP,
  quantityAlreadyAddedOnCart,
  colorLabel,
  handleCustomSizeBlockToggle,
  customSizeBlockForVariantTwoIsOpen,
}: Props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isMobile } = useDevice();
  const { cart } = useSelector((state: RootState) => state.cart);
  const [hoveredSku, setHoveredSku] = useState('');
  const isNoLongerAvailable = sizeNoLongerAvailable(cart, productRef, colorRef, sizeVariants);
  const [isSizeOpen, setIsSizeOpen] = useState(false);
  const [isColorOpen, setIsColorOpen] = useState(false);
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [isCtaDisplayed, setIsCtaDisplayed] = useState(true);
  const ctaRef = useRef<HTMLDivElement>(null);

  const sortedSizes = sortSizes(sizeVariants ?? []);

  const initSize = () => {
    if (sortedSizes.length === 1) {
      const [uniqueSize] = sortedSizes;
      if (getIsSizeAvailable(uniqueSize)) {
        return dispatch(changeSize(uniqueSize));
      }
    }
    if (size.sku) {
      const sameSize = sortedSizes.find(
        (s) =>
          (s.size && s.size === size.size) ||
          (s.bandSize && s.cupSize && s.bandSize === size.bandSize && s.cupSize === size.cupSize)
      );
      if (sameSize && getIsSizeAvailable(sameSize)) {
        return dispatch(changeSize(sameSize));
      }
    }
    return dispatch(changeSize(emptySize));
  };

  useEffect(() => {
    const footerElement = document.getElementById('darjeeling-footer');
    const onScroll = () => {
      if (ctaRef.current) {
        const pdpCtaBottom = ctaRef.current.getBoundingClientRect().top + window.scrollY;
        const footerTop = footerElement?.offsetTop || 0;

        setIsCtaDisplayed(pdpCtaBottom < footerTop);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    initSize();
  }, [colorRef]);

  useEffect(() => {
    dispatch(setSizeErrMsg(''));
  }, [size, hoveredSku]);

  useScrollLock({
    ref: ctaRef.current,
    isActive: isSizeOpen || isColorOpen,
    withScrollToTop: true,
  });

  const hoveredSize = sortedSizes.find((size) => size.sku === hoveredSku);

  const activeColors = (colorVariants ?? []).filter((color) => color.status);

  const sizeMessage = getSizeMessage(hoveredSize);

  const onColorClick = (colorRef: string) => {
    const link = pathname.split('-').slice(0, -1).concat(colorRef).join('-');
    navigate(link);
  };

  const onSizeClick = (sku: string) => {
    const clickedSize = sortedSizes.find((size) => size.sku === sku);
    if (clickedSize && getIsSizeAvailable(clickedSize)) {
      dispatch(changeSize(clickedSize));
    }
  };

  const handleGiftCardInfo = (info: TGiftCardInfos) => {
    setIsCustomAmount(info.isAnotherAmount);
    onGiftCardInfo(info);
  };

  const handleColorChevronClick = () => {
    setIsColorOpen(!isColorOpen);
    setIsSizeOpen(false);
  };

  const handleSizeChevronClick = () => {
    setIsSizeOpen(!isSizeOpen);
    setIsColorOpen(false);
  };

  const handleCtaClick = () => {
    setIsColorOpen(false);
    if (status && getIsSizeAvailable(size)) {
      setIsSizeOpen(false);
    } else {
      setIsSizeOpen(true);
    }
    onCtaClick();
  };

  const handleBackgroundClick = () => {
    setIsSizeOpen(false);
    setIsColorOpen(false);
  };
  const giftCardInputTarget =
    getIsClient() && (document.getElementById('gift-card-input-fields') as HTMLElement);
  React.useEffect(() => {
    if (giftCardErrMsg && isMobile) {
      if (giftCardErrMsg?.amount || giftCardErrMsg?.customAmount) {
        setIsSizeOpen(true);
      }
      if (!(giftCardErrMsg?.amount || giftCardErrMsg?.customAmount)) {
        setIsSizeOpen(false);
      }
      if (
        giftCardErrMsg.receiverEmail ||
        giftCardErrMsg.receiverName ||
        giftCardErrMsg.senderName
      ) {
        if (giftCardInputTarget) {
          window.scrollTo({
            top: giftCardInputTarget.offsetTop - heights.HEADER_MOBILE,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [giftCardErrMsg]);

  const isSelectorOpen = isColorOpen || isSizeOpen;

  if (isMobile && !isGiftCard) {
    return (
      <StyledBackground
        customSizeBlockForVariantTwoIsOpen={customSizeBlockForVariantTwoIsOpen}
        onClick={() => handleCustomSizeBlockToggle(false)}
      />
    );
  }

  return isMobile ? (
    <>
      <Transition
        in={isSelectorOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <Box
            position="fixed"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.3)"
            left={0}
            opacity={opacities.BACKGROUND}
            zIndex={zIndex.PRODUCT_SELECTOR_MODAL - 1}
            transition={transitions.GENERIC}
            onClick={handleBackgroundClick}
            style={{
              ...transitionStylesBackground[state],
            }}
          />
        )}
      </Transition>

      <Box
        position="fixed"
        zIndex={isSelectorOpen ? zIndex.PRODUCT_SELECTOR_MODAL : zIndex.STICKY}
        bg="WHITE"
        mx="-16px"
        width="100%"
        bottom={0}
        ref={ctaRef}
        transition={`transform ${durations.ENTER}ms ease-in-out`}
        transform={isCtaDisplayed ? 'translateY(0)' : 'translateY(100%)'}
        pointerEvents={isCtaDisplayed ? 'initial' : 'none'}
      >
        <Transition
          in={isSelectorOpen}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 0, exit: durations.EXIT }}
        >
          {(state) => (
            <Box
              m="m"
              display="grid"
              gridGap="m"
              transition={transitions.GENERIC}
              style={{
                ...transitionStylesContent[state],
              }}
            >
              {isColorOpen && (
                <Colors
                  objectID={objectID}
                  productRef={productRef}
                  colorRef={colorRef}
                  detailedColorLabel={detailedColorLabel}
                  onColorClick={onColorClick}
                  colorVariants={activeColors}
                  setColorBlockIsOpen={handleColorChevronClick}
                  isStickyCta
                />
              )}

              {isSizeOpen &&
                (!isGiftCard ? (
                  <Sizes
                    objectID={objectID}
                    sizeMessage={sizeMessage}
                    sortedSizes={sortedSizes}
                    setHoveredSku={setHoveredSku}
                    isNoLongerAvailable={isNoLongerAvailable}
                    errMsg={sizeErrMsg}
                    selectedSku={size.sku}
                    setSelectedSku={onSizeClick}
                    setSizeBlockIsOpen={handleSizeChevronClick}
                    isStickyCta
                  />
                ) : (
                  <AmountSelector
                    objectID={objectID}
                    onAmountChange={handleNewAmount}
                    noAmountSelected={isCustomAmount}
                    giftCardAmount={giftCardAmount}
                    giftCardErrMsg={giftCardErrMsg}
                    giftCardInfos={giftCardInfos}
                    onIconClick={handleSizeChevronClick}
                    onGiftCardInfo={handleGiftCardInfo}
                  />
                ))}
            </Box>
          )}
        </Transition>

        <Box
          display="grid"
          gridAutoFlow="column"
          gridTemplateColumns={isGiftCard ? '1fr 1fr' : '1fr 1fr 1fr'}
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="LIGHT"
          height={`${heights.CTA_HEIGHT}px`}
          zIndex={zIndex.STICKY}
          position="relative"
        >
          {!isGiftCard && (
            <Box
              display="grid"
              alignItems="center"
              justifyContent="center"
              gridAutoFlow="column"
              borderRight="1px solid"
              borderColor="LIGHT"
              background="WHITE"
              gridGap="s"
              onClick={handleColorChevronClick}
            >
              <Text preset="caption">{LBL_COLOR}</Text>
              <Box
                background={`url(${getColorImageLink(realColorRef || colorRef)})`}
                borderRadius="50%"
                border={getIsColorFilterWhitish(colorLabel) ? 'solid 1px #000' : 'none'}
                size="12px"
              />
              <Icon name={isColorOpen ? 'chevronDown' : 'chevronUp'} size={7} />
            </Box>
          )}
          <Box
            display="grid"
            alignItems="center"
            justifyContent="center"
            gridAutoFlow="column"
            gridGap="s"
            background="WHITE"
            onClick={handleSizeChevronClick}
          >
            <Text
              preset="caption"
              color={
                giftCardErrMsg?.amount || giftCardErrMsg?.customAmount ? colors.ERROR : colors.BLACK
              }
            >
              {!isGiftCard ? LBL_SIZE : LBL_AMOUNT}
            </Text>
            <Text
              preset="caption"
              fontWeight="bold"
              id={`product-modal-${objectID}-selected-size`}
              width="30px"
              color={
                giftCardErrMsg?.amount || giftCardErrMsg?.customAmount ? colors.ERROR : colors.BLACK
              }
            >
              {!isGiftCard ? getSizeLabel(size) : giftCardAmount + '€'}
            </Text>
            <Icon name={isSizeOpen ? 'chevronDown' : 'chevronUp'} size={7} />
          </Box>
          <DynamicButton
            id="btn-add-to-cart"
            onClick={handleCtaClick}
            data={CTA_CONTENT(true)}
            feedback={isQuickAddOnPDP ? Status.Default : ctaState}
            disabled={!status}
          />
        </Box>
      </Box>
    </>
  ) : (
    <>
      {!isGiftCard ? (
        <>
          <Colors
            objectID={objectID}
            productRef={productRef}
            colorRef={colorRef}
            detailedColorLabel={detailedColorLabel}
            onColorClick={onColorClick}
            colorVariants={activeColors}
          />
          <Sizes
            objectID={objectID}
            sizeMessage={sizeMessage}
            sortedSizes={sortedSizes}
            setHoveredSku={setHoveredSku}
            isNoLongerAvailable={isNoLongerAvailable}
            errMsg={sizeErrMsg}
            selectedSku={size.sku}
            setSelectedSku={onSizeClick}
            setSizeBlockIsOpen={handleSizeChevronClick}
          />
          <QuantitySelector
            objectID={objectID}
            quantity={quantity}
            size={size}
            quantityAlreadyAddedOnCart={quantityAlreadyAddedOnCart}
          />
        </>
      ) : (
        <Box display={'grid'} gridGap={'s'}>
          <AmountSelector
            objectID={objectID}
            onAmountChange={handleNewAmount}
            noAmountSelected={isCustomAmount}
            giftCardAmount={giftCardAmount}
            giftCardErrMsg={giftCardErrMsg}
            giftCardInfos={giftCardInfos}
            onGiftCardInfo={handleGiftCardInfo}
          />
          <GiftCardCustomInfos
            giftCardErrMsg={giftCardErrMsg}
            onGiftCardInfo={handleGiftCardInfo}
            isCustomAmountDeselected={isCustomAmountDeselected}
            giftCardInfos={giftCardInfos}
          />
        </Box>
      )}
    </>
  );
};
