import React, { RefObject } from 'react';
import styled from '@emotion/styled';

import { Product } from '../../product/types';
import { ProductCard } from '../../catalog/components/ProductCard';
import { CatalogProduct } from '../../catalog/types';
import { RecommendationTracking } from '../types';
import { breakpoints } from '../../../design-system';

const StyledRecommendationsContentBlock = styled.div<{ isFeedBack: boolean; isCartPage: boolean }>`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin-right: -16px;
  padding-right: 16px;

  @media (min-width: ${breakpoints.S}px) {
    margin-right: 0;
    padding-right: 0;
  }

  .recommendations-product-wrapper {
    flex: ${(props) => (props.isCartPage ? '0 0 calc(50% - 8px)' : '0 0 auto')};
    box-sizing: border-box;
    scroll-snap-align: start;
    max-width: 320px;
    min-width: ${(props) => (props.isFeedBack ? 'auto' : props.isCartPage ? '142px' : '250px')};
    width: ${(props) => (props.isFeedBack ? '142px' : 'auto')};

    @media (min-width: ${breakpoints.S}px) {
      flex: ${(props) => (props.isCartPage ? '0 0 calc(25% - 16px)' : '0 0 auto')};
      width: ${(props) => (props.isFeedBack ? '172px' : 'auto')};
    }

    @media (min-width: ${breakpoints.M}px) {
    }
  }
`;

type Props = {
  id: string;
  hits: Product[];
  tracking?: RecommendationTracking;
  containerRef: RefObject<HTMLDivElement>;
};

export const RecommendationsContent = ({ id, hits, tracking, containerRef }: Props) => {
  const isFeedBack = id === 'cross-sell';
  const isCartPage = id === 'cart-page';
  return (
    <StyledRecommendationsContentBlock
      ref={containerRef}
      isFeedBack={isFeedBack}
      isCartPage={isCartPage}
    >
      {hits?.map((hit, index) => {
        const product = {
          ...hit,
          objectID: `${hit.productRef}/${hit.colorRef}`,
          rcFilter: `${hit.productRef}-${hit.colorRef}`,
        };
        return (
          <div
            key={`${id}-recommendations-product-${hit.productRef}/${hit.colorRef}`}
            className="recommendations-product-wrapper"
          >
            <ProductCard
              hit={product as unknown as CatalogProduct}
              trackingId={tracking?.[index]?.id}
              isFeedback={isFeedBack}
              leftAlignToolTip
              reverseToolTip
            />
          </div>
        );
      })}
    </StyledRecommendationsContentBlock>
  );
};
