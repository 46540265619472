export type State = {
  isFetching: boolean;
  errMsg: string;
  blocks: RecommendationBlocks;
};

export type RecommendationBlocks = {
  crossSell: Recommendation;
  upSell: Recommendation;
  home: Recommendation;
  cart: Recommendation;
  search: Recommendation;
};

export type Recommendation = {
  filter: string;
  tracking?: RecommendationTracking;
  recommendedProducts: string[];
};

export type RecommendationTracking = {
  id: string;
  trackingId: string;
}[];

export type LoadProductRecommendationsParams = {
  productRef?: string;
  colorRef?: string;
};

export enum RecommendationPageIDs {
  product = '1200',
  home = '1000',
  cart = '1600',
  search = '3400',
}

export enum RecommendationBlocksIDs {
  home = '1',
  crossSell = '2',
  upSell = '3',
  cart = '4',
  search = '5',
}

export enum RecommendationTypes {
  crossSell = 'crossSell',
  upSell = 'upSell',
  home = 'home',
  cart = 'cart',
  search = 'search',
}
