import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import account from '../modules/account/reducers';
import auth from '../modules/auth/reducers';
import cart from '../modules/cart/reducers';
import catalog from '../modules/catalog/reducers';
import cms from '../modules/cms/reducers';
import ecart from '../modules/ecart/reducers';
import filters from '../modules/filters/reducers';
import form from '../modules/form/reducers';
import { modal, popup, sidebar, common, modalNew } from '../modules/common/reducers';
import payment from '../modules/payment/reducers';
import pickup from '../modules/pickup/reducers';
import product from '../modules/product/reducers';
import recommendations from '../modules/recommendations/reducers';
import stores from '../modules/store-locator/reducers';
import tracking from '../modules/tracking/reducers';
import wishlist from '../modules/wishlist/reducers';

const reducers = {
  account,
  auth,
  cart,
  catalog,
  cms,
  common,
  ecart,
  filters,
  form,
  modal,
  modalNew,
  payment,
  pickup,
  product,
  popup,
  recommendations,
  sidebar,
  stores,
  tracking,
  wishlist,
};

const rootReducer = combineReducers(reducers);

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
