import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { getProductImage } from '../../product/utils';
import { colors, Divider, Icon } from '../../../design-system';
import { CrossSell } from '../../product/components/CrossSell';
import {
  DATE_OF_SEND,
  MSG_FEEDBACK_SUCCESS,
  MSG_RETURN_TO_BASKET,
  RECEIVER_EMAIL,
} from '../locale';
import { PromotionLabel } from '../../catalog/components/PromotionLabel';
import paths from '../../routing/paths';
import { breakpoints, durations, opacities } from '../../../design-system/systemprovider';
import { LBL_COLOR, LBL_QUANTITY, LBL_SIZE } from '../../product/locale';
import { formatPrice } from '../../common/utils';
import { ERESA_CONTINUE_TO_BUY } from '../../ecart/locale';
import { TXT_LINK_CART } from '../../cart/locale';
import { loadCart, setShowFeedback } from '../../cart/actions';
import { Link, useLocation } from 'react-router-dom';
import { closeModalNew } from '../../common/actions';
import { RootState } from 'src/shared/store/rootReducer';

type Props = {
  productRef: string;
  colorRef: string;
  productName: string;
  collectionName: string;
  quantity: number;
  setProductQuantityAdded?: (value: number) => void;
  onClose?: () => void;
  storePrice: number;
  originalPrice: number;
  size: string;
  colorLabel: string;
  promotionLabel?: string;
  promotionPercentage?: string;
  promotionBackgroundColor?: string;
  promotionTextColor?: string;
  isOutlet?: boolean;
  objectID: string;
  email?: string;
  date?: string;
  priceGiftCard?: number;
  isGiftCard?: boolean;
};

const PRODUCT_IMAGE_WIDTH = 104;
const PRODUCT_IMAGE_WIDTH_MOBILE = 68;
const PRODUCT_IMAGE_MAX_HEIGHT = 139;

const StyledFeedBackContainer = styled.div<{
  isGiftCard: boolean;
  isCrossSellFetching: boolean;
  isCrossSellProduct: boolean;
}>`
  padding: 16px;

  .feed-back-header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .feed-back-icon-message-block {
    display: flex;
    align-items: center;
    gap: 16px;

    p {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 700;
      color: ${colors.BLACK};
    }
  }

  .feed-back-product-description-block {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;

    img {
      width: ${PRODUCT_IMAGE_WIDTH_MOBILE}px;
      border-radius: 8px;

      @media (min-width: ${breakpoints.S}px) {
        width: ${PRODUCT_IMAGE_WIDTH}px;
        max-height: ${PRODUCT_IMAGE_MAX_HEIGHT}px;
      }
    }
  }

  .feed-back-product-info-block {
    display: grid;
    width: 100%;
    gap: 8px;
  }

  .feed-back-product-name-price-block {
    display: grid;
    gap: 4px;

    @media (min-width: ${breakpoints.S}px) {
      gap: 8px;
    }

    .feed-back-category-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: calc(100vw - ${PRODUCT_IMAGE_WIDTH_MOBILE + 48}px);

      @media (min-width: ${breakpoints.S}px) {
        width: calc(95vw - ${PRODUCT_IMAGE_WIDTH + 48}px);
      }

      @media (min-width: ${breakpoints.M}px) {
        width: calc(770px - ${PRODUCT_IMAGE_WIDTH + 48}px);
      }

      p {
        margin: 0;
      }

      .feed-back-category-price {
        font-size: 1.6rem;
        font-weight: 700;
      }

      .feed-back-name {
        font-size: 1.4rem;
        color: ${colors.MAIN_GREY};
        text-transform: capitalize;
      }

      .feed-back-category-price,
      .feed-back-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70%;

        @media (min-width: ${breakpoints.S}px) {
          max-width: 80%;
        }
      }

      .feed-back-store-price {
        font-size: 1.6rem;
        font-weight: 700;
        color: ${colors.BLACK};
      }

      .feed-back-original-price {
        font-size: 1.6rem;
        color: ${colors.GREY2};
        text-decoration: line-through;
      }
    }
  }

  .feed-back-product-details-block {
    display: flex;
    align-items: ${(props) => (props.isGiftCard ? 'left' : 'initial')};
    flex-direction: ${(props) => (props.isGiftCard ? 'column' : 'row')};
    gap: ${(props) => (props.isGiftCard ? '4px' : '16px')};

    p,
    span {
      margin: 0;
      font-size: 1.2rem;
      color: ${colors.MAIN_GREY};

      @media (min-width: ${breakpoints.S}px) {
        font-size: 1.4rem;
      }
    }
    p {
      font-weight: 400;
    }
    span {
      font-weight: 700;
    }
  }

  .feed-back-color-size-quantity-block {
    display: flex;
    flex-direction: ${(props) => (props.isGiftCard ? 'column' : 'row')};
    gap: 4px;
  }

  .feed-back-color-size-quantity-block-gift-card {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  .feed-back-cross-sell-block {
    margin: ${(props) =>
      props.isCrossSellProduct && !props.isCrossSellFetching ? '0 -16px 12px -16px' : '8px 0'};

    @media (min-width: ${breakpoints.S}px) {
      margin: ${(props) =>
        props.isCrossSellProduct && !props.isCrossSellFetching ? '0 -16px 12px -16px' : '4px 0'};
    }
  }

  .feed-back-buttons-block {
    margin-top: ${(props) => (props.isGiftCard ? '16px' : 0)};
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    @media (min-width: ${breakpoints.S}px) {
      flex-direction: row;
    }

    p {
      font-size: 1.4rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .feed-back-close-button,
  .feed-back-cart-page-close-button {
    background-color: ${colors.WHITE};
    color: ${colors.BLACK};
    border: solid 1px ${colors.BLACK};
    cursor: pointer;
  }

  .feed-back-cart-page-close-button {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }

  .feed-back-modal-link-to-cart-anchor {
    text-decoration: none;
    background-color: ${colors.BLACK};
    color: ${colors.WHITE};
  }

  .feed-back-close-button,
  .feed-back-modal-link-to-cart-anchor {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }
`;

export const FeedbackModal = ({
  productRef,
  colorRef,
  productName,
  collectionName,
  quantity,
  setProductQuantityAdded,
  onClose,
  storePrice,
  originalPrice,
  size,
  colorLabel,
  promotionLabel,
  promotionPercentage,
  promotionBackgroundColor,
  promotionTextColor,
  isOutlet,
  objectID,
  email,
  date,
  priceGiftCard,
  isGiftCard = false,
}: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    isFetching,
    blocks: {
      crossSell: { recommendedProducts },
    },
  } = useSelector((state: RootState) => state.recommendations);
  const image = getProductImage({
    productRef,
    productName,
    colorRef,
    colorLabel,
    position: 1,
    width: PRODUCT_IMAGE_WIDTH,
  });

  const [nameWithoutCategory, category] = productName?.split(' - ') ?? '';

  const onModalClose = () => {
    if (onClose) {
      onClose();
    }
    dispatch(setShowFeedback(false));
    dispatch(closeModalNew());
    if (setProductQuantityAdded) {
      setProductQuantityAdded(1);
    }
  };

  const onGoingToCartPage = () => {
    dispatch(setShowFeedback(false));
    if (setProductQuantityAdded) {
      setProductQuantityAdded(1);
    }
    dispatch(loadCart() as unknown as UnknownAction);
  };

  const isCartPage = pathname.startsWith(paths.CART);

  return (
    <StyledFeedBackContainer
      isGiftCard={isGiftCard}
      isCrossSellFetching={isFetching}
      isCrossSellProduct={Boolean(recommendedProducts.length)}
    >
      <div className="feed-back-header-block">
        <div className="feed-back-icon-message-block">
          <Icon name="checkmarkCircle" size={16} color={colors.MAIN_SUCCESS} />
          <p>{MSG_FEEDBACK_SUCCESS}</p>
        </div>
      </div>
      <div className="feed-back-product-description-block">
        <img src={image?.jpg} alt={image?.alt} />
        <div className="feed-back-product-info-block">
          <div className="feed-back-product-name-price-block">
            <div className="feed-back-category-name">
              <p className="feed-back-category-price">
                {isGiftCard
                  ? productName
                  : (category ?? collectionName ?? 'categorie').toLocaleUpperCase()}
              </p>
              <span className="feed-back-store-price">
                {formatPrice(isGiftCard && priceGiftCard ? priceGiftCard : storePrice)}
              </span>
            </div>
            {!isGiftCard && (
              <div className="feed-back-category-name">
                <p className="feed-back-name">{nameWithoutCategory ?? 'nom du produit'}</p>
                {originalPrice > storePrice && (
                  <span className="feed-back-original-price">{formatPrice(originalPrice)}</span>
                )}
              </div>
            )}
          </div>
          <PromotionLabel
            promotionPercentage={promotionPercentage}
            promotionLabel={promotionLabel}
            promotionTextColor={promotionTextColor}
            promotionBackgroundColor={promotionBackgroundColor}
            isOutlet={isOutlet}
            objectID={objectID}
          />
          <div className="feed-back-product-details-block">
            <div className="feed-back-color-size-quantity-block">
              <p>{isGiftCard ? RECEIVER_EMAIL : LBL_COLOR}</p>
              <span>{isGiftCard ? email : colorLabel}</span>
            </div>
            <div className={`feed-back-color-size-quantity-block${isGiftCard ? '-gift-card' : ''}`}>
              <p>{isGiftCard ? DATE_OF_SEND : LBL_SIZE}</p>
              <span>{isGiftCard ? new Date(date ?? '').toLocaleDateString('fr-FR') : size}</span>
            </div>
            {!isGiftCard && (
              <div className="feed-back-color-size-quantity-block">
                <p>{LBL_QUANTITY}</p>
                <span>{quantity}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isGiftCard && (
        <div className="feed-back-cross-sell-block">
          <Divider my="24px" />
          <CrossSell />
        </div>
      )}
      <div className="feed-back-buttons-block">
        <button
          className={`${
            isCartPage ? 'feed-back-cart-page-close-button' : 'feed-back-close-button'
          }`}
          type="button"
          data-testid="feed-back-modal-close-button"
          onClick={onModalClose}
        >
          <p>{isCartPage ? MSG_RETURN_TO_BASKET : ERESA_CONTINUE_TO_BUY}</p>
        </button>
        {!isCartPage && (
          <Link
            className="feed-back-modal-link-to-cart-anchor"
            to={paths.CART}
            aria-label="lien vers le panier"
            data-testid="feed-back-modal-link-to-cart-page"
            onClick={onGoingToCartPage}
          >
            <p>{TXT_LINK_CART}</p>
          </Link>
        )}
      </div>
    </StyledFeedBackContainer>
  );
};
