import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import qs from 'qs';
import { NavigationType, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { css } from '@emotion/core';
import Transition from 'react-transition-group/Transition';
import { Helmet } from 'react-helmet-async';

import { Text, breakpoints, colors } from '../../../design-system';
import { transitionStylesBackground } from '../../common/components/Sidebar';
import { zIndex, opacities, durations, transitions, heights } from '../../common/constants';
import withCommonUI from '../../common/hocs/withCommonUI';
import { WithSidebarActions } from '../../common/hocs/withSidebar';
import { WithFormActions } from '../../form/hocs/withForm';
import { RootState } from '../../../store/rootReducer';
import {
  addShippingType,
  setStep,
  setCheckoutAddresses,
  resetStep,
  setPaypalECS,
  resetStoreId,
  resetPickupStationId,
} from '../actions';
import { openSidebar, closeSidebar, openModal, closeModal } from '../../common/actions';
import { setFormValidation, setFormValues, setFocus, setFeedback } from '../../form/actions';
import {
  cartValiditySelector,
  numberOfItemsSelector,
  hasGiftSelector,
  hasDigitalSelector,
} from '../selectors';
import {
  loadUserDetails,
  setCartStep,
  setDeliveryStep,
  setIsFetching,
} from '../../account/actions';
import usePrevious from '../../common/hooks/usePrevious';
import useBodyScrollLock from '../../common/hooks/useBodyScrollLock';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { ERR_TERMS_OF_SALES } from '../locale';
import { AUTH_BAR_TITLE } from '../../auth/locale';
import { Address as AddressType } from '../../account/types';
import { CmsCart } from '../../cms/types';
import { Events } from '../../tracking/types';
import {
  Forms,
  FieldType,
  FormAddress,
  FormDelivery,
  FormPayment,
  FormFieldCallback,
} from '../../form/types';
import { PaymentResults } from '../../payment/types';
import { State, ShippingTypes, CartStep } from '../types';
import paths from '../../routing/paths';
import { scrollToTop, getPersistedData, persistData, noop } from '../../common/utils';
import { subscribeNewsletter } from '../../footer/utils';
import { validateMainlandFrancePostalCode } from '../utils';
import {
  validate,
  format,
  isCtaActive,
  validateName,
  formatedPhoneValue,
  isPhoneOnChangeValid,
} from '../../form/utils';
import { pushToGTM } from '../../tracking';
import { AuthWithSideBar } from '../../auth';
import { CartDeliveryStep } from './CartDeliveryStep';
import { CartFaq } from './CartFaq';
import { CartListningStep } from './CartListningStep';
import { Cta } from './Cta';
import { EmptyCart } from './EmptyCart';
import { Header } from './Header';
import { Loading } from '../../common/components/Loading';
import { Modal } from '../../common/components/Modal';
import { LoadingCart } from './LoadingCart';
import { Notifications } from './Notifications';
import OrderSuccessContainer from './OrderSuccess';
import { Payment } from '../../payment/components/Payment';
import { PaypalExpressButton } from '../../PaypalExpress/PaypalExpressButton';
import { PaypalExpressModalError } from '../../PaypalExpress/PaypalExpressModalError';
import { PickupStation } from '../../pickup/types';
import { Reassurance } from './Reassurance';
import { Summary } from './Summary';
import { ERR_ONLY_NUMBER_ALLOWED } from '../../form/locale';
import { CtaStickyMobile } from './CtaStickyMobile';
import { ValidationWarning } from './ValidationWarning';
import { Status as CtaStatus } from '../../../design-system/button/components/DynamicButton';
import { ErrorUserModal } from '../../account/components/ErrorUserModal';
import { hasToken } from '../../api';

const EmptyCartContainer = withCommonUI(EmptyCart);

type DispatchedActions = {
  setDeliveryStep: () => void;
  addShippingType: (type: ShippingTypes) => void;
  setCheckoutAddresses: ({
    billing,
    shippingType,
    pickupStation,
    shipping,
    storeId,
    giftMessage,
    contactPhone,
    isDigital,
  }: {
    billing: AddressType;
    shippingType?: ShippingTypes;
    pickupStation?: PickupStation;
    shipping?: AddressType;
    storeId?: string;
    giftMessage?: string;
    contactPhone?: string;
    isDigital?: boolean;
  }) => void;
  setStep: (step: CartStep) => void;
  resetStep: () => void;
} & WithSidebarActions &
  Omit<WithFormActions, 'setCtaState'>;

type DispatchedProps = {
  isLoggedIn: boolean;
  isCartItemsValid: boolean;
  numberOfItems: number;
  hasGift: boolean;
  hasDigital: boolean;
  forms: {
    shipping: FormAddress;
    billing: FormAddress;
    delivery: FormDelivery;
    payment: FormPayment;
  };
  cmsContent: CmsCart;
} & State;

type Props = DispatchedProps & DispatchedActions;

export const RIGHT_PANEL_WIDTH_PLUS_LEFT_PANEL_MARGIN = 514; // right panel (summary) 450px left panel margin 64px

export const Cart = ({
  addShippingType,
  cart,
  cmsContent,
  step,
  isLoggedIn,
  isCartItemsValid,
  numberOfItems,
  hasGift,
  setStep,
  openSidebar,
  isFetching,
  errMsg,
  forms,
  setFormValidation,
  setFormValues,
  setFocus,
  resetStep,
  setDeliveryStep,
  setFeedback,
  setCheckoutAddresses,
}: Props) => {
  const reassurance = cmsContent?.reassurance ?? [];
  const reassurances = cmsContent?.reassurances ?? [];
  const { shippingType, isValidated, isDigital, couponAccepted, notifications } = cart ?? {};
  const prevNumberOfItems = usePrevious(numberOfItems);
  const { isDesktop } = useMediaQueries();
  const { search } = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const { result, email, firstName, orderNumber } = qs.parse(search.slice(1));
  const dispatch = useDispatch();
  const { isFetching: isUserCartLoading, user } = useSelector((state: RootState) => state.account);
  const isPaypalECS = getPersistedData('isPaypalECS');
  const wishSubscribeWithPaypalECS = getPersistedData('subscribePaypalECS');
  const [isECSError, setIsECSError] = useState(false);
  const [isItemListBlockOpen, setIsItemListBlockOpen] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const { shipping, billing } = useSelector((state: RootState) => state.form);
  const { isOpen: isModalOpen } = useSelector((state: RootState) => state.modal);

  const { showBilling } = useSelector((state: RootState) => state.form.delivery.values);
  const [formErrorType, setFormErrorType] = useState<Forms | null>(null);
  const [openedFormType, setOpenedFormType] = useState<Forms | null>(null);

  useBodyScrollLock({
    isActive: isItemListBlockOpen,
    withScrollToTop: false,
    excludeId: 'cart-item-list-block-with-scroll-auto',
  });

  if (result === PaymentResults.Authorised && email && firstName && orderNumber) {
    dispatch(setPaypalECS({ email, firstName, orderNumber }));
  }

  useEffect(() => {
    return () => {
      resetStep();
    };
  }, []);

  useEffect(() => {
    if (step === CartStep.LISTING) {
      cart.storeId && dispatch(resetStoreId());
      cart.pickupStation && dispatch(resetPickupStationId());
    }
    if (isModalOpen) {
      dispatch(closeModal());
    }
    if (isLoggedIn && step === CartStep.DELIVERY) {
      dispatch(loadUserDetails() as unknown as UnknownAction);
    }
    scrollToTop();
    setFocus({ form: Forms.delivery, key: '' });
  }, [step]);

  useEffect(() => {
    if (
      !isLoggedIn ||
      !isCartItemsValid ||
      (((prevNumberOfItems && numberOfItems !== prevNumberOfItems) || !isValidated) &&
        numberOfItems > 0)
    ) {
      setStep(CartStep.LISTING);
    }
  }, [isCartItemsValid, isValidated, numberOfItems, isLoggedIn]);

  useEffect(() => {
    if (result && !isPaypalECS) {
      if (result === PaymentResults.Authorised) {
        setStep(CartStep.SUCCESS);
      } else if (result.toUpperCase() === PaymentResults.REDACTED) {
        setStep(CartStep.LISTING);
      } else {
        setFeedback({ form: Forms.payment, ok: false, message: '' });
        setStep(CartStep.PAYMENT);
      }
      navigate(paths.CART);
    }
    if (result && isPaypalECS) {
      if (result === PaymentResults.CANCELLED) {
        setIsECSError(true);
        persistData('isPaypalECS', false);
        persistData('subscribePaypalECS', false);
        setStep(CartStep.LISTING);
        navigate(paths.CART);
      }
      if (!(result === PaymentResults.Authorised || result === PaymentResults.CANCELLED)) {
        setStep(CartStep.LISTING);
        if (!isFetching && !isUserCartLoading) {
          navigate(paths.CART);
          setIsECSError(true);
          dispatch(
            openModal({
              content: <PaypalExpressModalError />,
              preset: isDesktop ? 'l' : 's',
            })
          );
        }
      }
      if (result === PaymentResults.Authorised) {
        if (wishSubscribeWithPaypalECS && !isLoggedIn && email) {
          subscribeNewsletter({ mail: email, origin: 'PaypalECS' });
        }
        setStep(CartStep.SUCCESS);
        navigate(paths.CART);
      }
    }
  }, [search, isFetching, isUserCartLoading, isLoggedIn]);

  useEffect(() => {
    if (showBilling) {
      handleShowBillingClick();
    }
    if (formErrorType) {
      setFormErrorType(null);
    }
    if (step < CartStep.PAYMENT) {
      setFocus({ form: Forms.delivery, key: '' });
      for (const property in forms) {
        for (const item in forms[property].validation) {
          if (forms[property].validation[item] !== '') {
            setFormValidation({
              form: property as Forms,
              values: {
                [item]: '',
              },
            });
          }
        }
      }
    }
  }, [shippingType, step]);

  useEffect(() => {
    if (isLoggedIn) {
      const checkUserSession = async () => {
        const isRefreshTokenOk = await hasToken();
        const isDeletedAccount = user?.error === 'customer_deleted';
        if (!isRefreshTokenOk || isDeletedAccount) {
          dispatch(
            openModal({
              content: (
                <ErrorUserModal
                  isSessionExpiration={!isRefreshTokenOk}
                  isDeletedAccount={isDeletedAccount}
                />
              ),
              preset: 'paymentError',
            })
          );
        }
      };
      checkUserSession();
    }
  }, [isLoggedIn, user?.error, step]);

  useEffect(() => {
    document.body.className =
      forms.payment.ctaState === CtaStatus.Loading ? 'disable-pointer-events' : '';
    return () => {
      document.body.className = '';
    };
  }, [forms.payment.ctaState]);

  if (errMsg) {
    return (
      <Text preset="caption" color="ERROR">
        {errMsg}
      </Text>
    );
  }
  if (result) {
    return <Loading />;
  }
  if (step === CartStep.SUCCESS) {
    return <OrderSuccessContainer />;
  }
  if (isFetching || isUserCartLoading) {
    return <LoadingCart />;
  }
  if (numberOfItems < 1) {
    return <EmptyCartContainer />;
  }

  const isCartValid = isValidated && isCartItemsValid;

  const validateField = ({
    key,
    value,
    country,
  }: {
    key: string;
    value: FieldType;
    country: string;
  }): string => {
    const requiredFields = [
      'firstName',
      'lastName',
      'street',
      'postal',
      'city',
      'phone',
      'country',
      shippingType === ShippingTypes.PICKUP && 'pickupPhone',
    ].filter(Boolean);

    const isRequired = requiredFields.includes(key);

    return validate({
      value: String(value),
      key,
      isRequired,
      country,
    });
  };

  const validateForm = (form: Forms): boolean => {
    const {
      values,
      values: { country },
    } = forms[form];
    setFocus({ form, key: '' });
    const newErrMsgs = forms[form].validation;
    let fieldToFocus = '';

    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField({ key, value: values[key], country });
      newErrMsgs[key] = errMsg;
      setFormValidation({ form, values: { [key]: errMsg } });
      if (errMsg && !fieldToFocus) {
        fieldToFocus = key;
      }
      return errMsg;
    });
    setFocus({ form, key: fieldToFocus });
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleLogin = () => {
    dispatch(setIsFetching(true));
    dispatch(setCartStep() as unknown as UnknownAction);
  };

  const handleCtaClick = () => {
    switch (step) {
      case CartStep.LISTING: {
        if (!isCartValid) {
          break;
        }
        if (isLoggedIn) {
          setDeliveryStep();
        } else {
          openSidebar({
            title: AUTH_BAR_TITLE,
            content: <AuthWithSideBar onLogin={handleLogin} />,
          });

          pushToGTM(Events.authBarOpen, {});
        }
        break;
      }
      case CartStep.DELIVERY: {
        const firstNameValidationError = validate({
          key: 'firstName',
          value: user?.firstName ?? '',
        });
        const lastNameValidationError = validate({ key: 'lastName', value: user?.lastName ?? '' });
        const isUserNameValid = !firstNameValidationError && !lastNameValidationError;
        const storeOrPickupShipping =
          shippingType === ShippingTypes.STORE || shippingType === ShippingTypes.PICKUP;
        if (!isUserNameValid && storeOrPickupShipping) {
          setIsValidationModalOpen(true);
          break;
        }
        if (isItemListBlockOpen) {
          setIsItemListBlockOpen(false);
        }
        setFeedback({ form: Forms.payment, ok: true, message: '' });
        const isDeliveryFormValid = validateForm(Forms.delivery);
        if (!isDeliveryFormValid && !isDigital) {
          break;
        }

        if (!forms.delivery.values.isConditionsAccepted) {
          setFormValidation({
            form: Forms.delivery,
            values: {
              isConditionsAccepted: ERR_TERMS_OF_SALES,
            },
          });
          setFocus({
            form: Forms.delivery,
            key: 'isConditionsAccepted',
          });
          break;
        }

        if (
          forms.delivery.values.showBilling ||
          shippingType === ShippingTypes.STORE ||
          shippingType === ShippingTypes.PICKUP ||
          isDigital
        ) {
          const isBillingFormValid = validateForm(Forms.billing);
          if (!isBillingFormValid) {
            setFormErrorType(Forms.billing);
            break;
          }
        }

        if (isDigital) {
          setCheckoutAddresses({ shippingType, isDigital, billing: forms.billing.values });
        } else if (shippingType === ShippingTypes.HOME) {
          const isShippingFormValid = validateForm(Forms.shipping);
          if (!isShippingFormValid) {
            setFormErrorType(Forms.shipping);
            break;
          }

          const errMsgPostalCode = validateMainlandFrancePostalCode(forms.shipping.values.postal);
          if (errMsgPostalCode) {
            setFeedback({ form: Forms.delivery, ok: false, message: errMsgPostalCode });
            break;
          }

          setCheckoutAddresses({
            shippingType,
            shipping: forms.shipping.values,
            billing: forms.delivery.values.showBilling
              ? forms.billing.values
              : forms.shipping.values,
            giftMessage: forms.delivery.values.giftMessage,
          });
        } else if (shippingType === ShippingTypes.STORE) {
          if (!cart.storeId) {
            setFocus({ form: Forms.delivery, key: 'search' });
            break;
          }

          setCheckoutAddresses({
            shippingType,
            storeId: cart.storeId,
            billing: forms.billing.values,
            giftMessage: forms.delivery.values.giftMessage,
          });
        } else if (shippingType === ShippingTypes.PICKUP) {
          if (!cart.pickupStation?.id) {
            setFocus({ form: Forms.delivery, key: 'search' });
            break;
          }

          setCheckoutAddresses({
            shippingType,
            pickupStation: cart.pickupStation,
            billing: forms.billing.values,
            giftMessage: forms.delivery.values.giftMessage,
            contactPhone: forms.delivery.values.pickupPhone,
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleFieldChange = ({ form, key, value }: FormFieldCallback) => {
    const { country } = forms[form].values;
    if (validateName(key, value)) {
      return null;
    }

    if (
      key === 'phone' &&
      typeof value === 'string' &&
      !isPhoneOnChangeValid({
        value,
        dispatch,
        formType: form,
        form: form === 'shipping' ? shipping : billing,
      })
    ) {
      return null;
    }
    if (key === 'pickupPhone' && typeof value === 'string' && value) {
      if (value.replace(/\s/g, '').length > 10) {
        return null;
      }
      if (!value.match(/^[0-9 ]+$/)) {
        setFormValidation({ form, values: { [key]: ERR_ONLY_NUMBER_ALLOWED } });
        return null;
      }
      setFormValidation({ form, values: { [key]: '' } });
    }

    if (forms[form].validation[key] && key !== 'phone' && key !== 'pickupPhone') {
      setFormValidation({ form, values: { [key]: validateField({ key, value, country }) } });
    }

    const newValue =
      (key === 'phone' || key === 'pickupPhone') && typeof value === 'string'
        ? formatedPhoneValue(value)
        : value;

    return setFormValues({
      form,
      values: {
        [key]:
          typeof newValue === 'boolean' || key === 'pickupPhone'
            ? newValue
            : format({ value: newValue, key }),
      },
    });
  };

  const handleFieldBlur = ({ form, key, value }: FormFieldCallback) => {
    const { country } = forms[form].values;
    const errMsg = validateField({ key, value, country });
    setFormValidation({ form, values: { [key]: errMsg } });
  };

  const handleClickBack = () => {
    if (step === CartStep.LISTING) {
      if (navigationType === NavigationType.Pop) {
        navigate(paths.HOME);
      } else {
        isECSError ? navigate(paths.HOME) : navigate(-1);
      }
    } else {
      setStep(step - 1);
    }
  };

  const handleConditionsAcceptedClick = () => {
    handleFieldChange({
      form: Forms.delivery,
      key: 'isConditionsAccepted',
      value: !forms.delivery.values.isConditionsAccepted,
    });
    if (
      forms.delivery.values.isConditionsAccepted &&
      forms.delivery.focus === 'isConditionsAccepted'
    ) {
      setFocus({
        form: Forms.delivery,
        key: '',
      });
    }
  };

  const handleShippingMethodClick = (type: ShippingTypes) => {
    addShippingType(type);
    handleFieldChange({
      form: Forms.delivery,
      key: 'showBilling',
      value: false,
    });
  };

  const handleShowBillingClick = () => {
    handleFieldChange({
      form: Forms.delivery,
      key: 'showBilling',
      value: !forms.delivery.values.showBilling,
    });
  };

  const handleShowGiftMessageClick = () => {
    handleFieldChange({
      form: Forms.delivery,
      key: 'giftMessage',
      value: '',
    });
    handleFieldChange({
      form: Forms.delivery,
      key: 'showGiftMessage',
      value: !forms.delivery.values.showGiftMessage,
    });
  };

  return (
    <div
      id="cart-container"
      css={css`
        padding-bottom: ${heights.CART_STEPS_PADDING_BOTTOM_MOBILE[step]}px;
        max-width: ${breakpoints.XL}px;
        margin: auto;
        @media (min-width: ${breakpoints.S}px) {
          padding-bottom: 0;
        }
      `}
    >
      <Helmet>
        <title>{`${step > CartStep.LISTING ? 'Check-out' : 'Panier'} | Darjeeling`}</title>
      </Helmet>
      <Transition
        in={isItemListBlockOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <div
            css={css`
              position: fixed;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.3);
              left: 0;
              opacity: ${opacities.BACKGROUND};
              z-index: ${zIndex.PRODUCT_SELECTOR_MODAL - 1};
              transition: ${transitions.GENERIC};
            `}
            data-cy="cart-background-click-to-close-item-list-summary-block-mobile"
            onClick={() => setIsItemListBlockOpen(false)}
            style={{
              ...transitionStylesBackground[state],
            }}
          />
        )}
      </Transition>
      <div
        id="cart-main-block"
        css={css`
          display: flex;
          flex-direction: column;
          @media (min-width: ${breakpoints.S}px) {
            padding-bottom: 0;
          }
          @media (min-width: ${breakpoints.M}px) {
            flex-direction: row;
          }
        `}
      >
        <div
          id="cart-first-block"
          css={css`
            width: 100%;
            @media (min-width: ${breakpoints.M}px) {
              width: calc(100% - 450px);
            }
          `}
        >
          <Header
            step={step}
            handleClickBack={handleClickBack}
            isLoading={isFetching || isUserCartLoading}
          />
          {step === CartStep.LISTING ? (
            <CartListningStep
              cmsContent={cmsContent}
              couponAccepted={couponAccepted}
              notifications={notifications}
              step={step}
              hasGift={hasGift}
              isDigital={isDigital}
            />
          ) : null}
          {step === CartStep.DELIVERY ? (
            <CartDeliveryStep
              couponAccepted={couponAccepted}
              notifications={notifications}
              step={step}
              handleShowBillingClick={handleShowBillingClick}
              handleShowGiftMessageClick={handleShowGiftMessageClick}
              handleShippingMethodClick={handleShippingMethodClick}
              handleFieldBlur={handleFieldBlur}
              handleFieldChange={handleFieldChange}
              formErrorType={formErrorType}
              setFormErrorType={setFormErrorType}
              setOpenedFormType={setOpenedFormType}
            />
          ) : null}
          {step === CartStep.PAYMENT ? (
            <div
              css={css`
                margin: 0 16px;
                @media (min-width: ${breakpoints.M}px) {
                  margin: 0 24px;
                }
                @media (min-width: ${breakpoints.L}px) {
                  margin: 0 72px;
                }
              `}
            >
              <Notifications
                couponAccepted={couponAccepted}
                notifications={notifications}
                step={step}
              />
              <Payment />
            </div>
          ) : null}
        </div>
        <div
          id="cart-second-block"
          css={css`
            background: ${colors.WHITE};
            padding: 0 16px;
            @media (min-width: ${breakpoints.S}px) {
              background: ${colors.BACKGROUND};
              padding-bottom: 16px;
              margin: 0 16px 16px 16px;
            }
            @media (min-width: ${breakpoints.M}px) {
              background: ${colors.WHITE};
              border-left: solid 1px ${colors.LIGHT};
              width: 450px;
              height: 100vh;
              position: sticky;
              top: 0;
              right: 0;
              overflow-y: auto;
              padding: 0 40px 16px 40px;
              margin: 0;
            }
          `}
        >
          <Summary onIsConditionsAcceptedClick={handleConditionsAcceptedClick} />
          {step < CartStep.PAYMENT ? (
            <div
              css={css`
                display: none;
                @media (min-width: ${breakpoints.S}px) {
                  display: grid;
                  grid-gap: 32px;
                }
              `}
            >
              <Cta
                onClick={handleCtaClick}
                step={step}
                isActive={isCtaActive(forms) && forms[Forms.delivery].focus !== 'search'}
                disabled={!isCartValid || Boolean(formErrorType || openedFormType)}
                ctaState={
                  step === CartStep.LISTING ? forms.delivery.ctaState : forms.payment.ctaState
                }
                formErrorType={formErrorType}
                openedFormType={openedFormType}
              />
            </div>
          ) : null}
          <div
            css={css`
              display: none;
              @media (min-width: ${breakpoints.S}px) {
                margin-bottom: 24px;
                display: grid;
                grid-gap: 32px;
              }
            `}
          >
            {step === CartStep.LISTING ? <PaypalExpressButton isCartValid={isCartValid} /> : null}
          </div>
          {step < CartStep.PAYMENT ? (
            <Reassurance reassurance={reassurance} reassurances={reassurances} />
          ) : null}
        </div>
        {step === CartStep.LISTING ? (
          <div
            css={css`
              display: ${cmsContent.cart_faq_is_active_mobile ? 'block' : 'none'};
              @media (min-width: ${breakpoints.S}px) {
                margin: 16px;
              }
              @media (min-width: ${breakpoints.M}px) {
                display: none;
              }
            `}
          >
            <CartFaq cmsContent={cmsContent.body} />
          </div>
        ) : null}
      </div>
      <CtaStickyMobile
        step={step}
        isItemListBlockOpen={isItemListBlockOpen}
        setIsItemListBlockOpen={setIsItemListBlockOpen}
        isCartValid={isCartValid}
        handleCtaClick={handleCtaClick}
        forms={forms}
        formErrorType={formErrorType}
        openedFormType={openedFormType}
      />
      <Modal
        preset="nameValidationWarning"
        isOpen={isValidationModalOpen}
        background={isValidationModalOpen}
        closeModal={noop}
        content={<ValidationWarning close={() => setIsValidationModalOpen(false)} />}
      />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    isLoggedIn: state.auth.isLoggedIn,
    ...state.cart,
    forms: {
      shipping: state.form.shipping,
      billing: state.form.billing,
      delivery: state.form.delivery,
      payment: state.form.payment,
    },
    cmsContent: state.cms.cart,
    isCartItemsValid: cartValiditySelector(state),
    numberOfItems: numberOfItemsSelector(state),
    hasGift: hasGiftSelector(state),
    hasDigital: hasDigitalSelector(state),
  }),
  {
    addShippingType,
    setDeliveryStep,
    setCheckoutAddresses,
    setStep,
    openSidebar,
    closeSidebar,
    setFormValues,
    setFormValidation,
    setFocus,
    setFeedback,
    resetStep,
  }
)(Cart);
