import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { Colors } from './Colors';
import Sizes from './Sizes';
import InfoPanel from './InfoPanel';
import { Product, Size } from '../types';
import {
  getIsSizeAvailable,
  getSizeLabel,
  getSizeMessage,
  sizeNoLongerAvailable,
  sortSizes,
} from '../utils';
import { RootState } from 'src/shared/store/rootReducer';
import { changeSize } from '../actions';
import { DynamicButton, DynamicButtonStatus, Icon, colors } from '../../../design-system';
import { CTA_CONTENT, ERR_CHOOSE_SIZE, LBL_SIZE_SELECTED } from '../locale';
import { durations, zIndex } from '../../common/constants';
import useScrollLock from '../../common/hooks/useScrollLock';

type Props = {
  product: Product;
  size: Size;
  ratingAverage: string;
  ratingCount: string;
  reviewsRef: React.RefObject<HTMLDivElement>;
  showReviews: boolean;
  sizeErrMsg: string;
  ctaState: DynamicButtonStatus;
  customSizeBlockForVariantTwoIsOpen: boolean;
  handleCustomSizeBlockToggle: (value: boolean) => void;
  handleCtaClick: () => Promise<void>;
};

const StyledContainer = styled.div<{
  customSizeBlockForVariantTwoIsOpen: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .size-label {
    font-weight: 700;
  }

  .size-block-variant-two {
    z-index: ${zIndex.PRODUCT_SELECTOR_MODAL};
    margin-left: -16px;
    background-color: ${colors.WHITE};
    width: 100%;
    position: fixed;
    bottom: 0;
    transition: transform ${durations.ENTER}ms ease-in-out;
    transform: ${(props) =>
      props.customSizeBlockForVariantTwoIsOpen ? 'translateY(0)' : 'translateY(100%)'};
  }

  .button-block {
    padding: 16px;
  }

  .size-button-for-variant-two {
    background-color: unset;
    width: 100%;
    height: 46px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px ${colors.LIGHT};
    color: ${colors.BLACK};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const ProductVariantBlock = ({
  product,
  size,
  ratingAverage,
  ratingCount,
  reviewsRef,
  showReviews,
  ctaState,
  handleCtaClick,
  sizeErrMsg,
  customSizeBlockForVariantTwoIsOpen,
  handleCustomSizeBlockToggle,
}: Props) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refVariantTwoBlock = useRef<HTMLDivElement | null>(null);
  const [hoveredSku, setHoveredSku] = useState('');
  const { cart } = useSelector((state: RootState) => state.cart);

  useScrollLock({
    isActive: customSizeBlockForVariantTwoIsOpen,
    ref: refVariantTwoBlock as unknown as HTMLElement,
    withScrollToTop: true,
  });

  const {
    productRef,
    colorRef,
    detailedColorLabel,
    productName,
    colorVariants,
    sizeVariants,
    isRecyclable,
  } = product ?? {};

  const { sku } = size;
  const { storePrice, originalPrice } = sku ? size : (product ?? {});
  const objectID = `${productRef}/${colorRef}`;
  const activeColors = (colorVariants ?? []).filter((color) => color.status);
  const sortedSizes = sortSizes(sizeVariants ?? []);
  const hoveredSize = sortedSizes.find((size) => size.sku === hoveredSku);
  const sizeMessage = getSizeMessage(hoveredSize);
  const isNoLongerAvailable = sizeNoLongerAvailable(cart, productRef, colorRef, sizeVariants);

  const onColorClick = (colorRef: string) => {
    const link = pathname.split('-').slice(0, -1).concat(colorRef).join('-');
    navigate(link);
  };

  const onSizeClick = (sku: string) => {
    const clickedSize = sortedSizes.find((size) => size.sku === sku);
    if (clickedSize && getIsSizeAvailable(clickedSize)) {
      dispatch(changeSize(clickedSize));
    }
  };

  return (
    <StyledContainer
      id="variant-block"
      customSizeBlockForVariantTwoIsOpen={customSizeBlockForVariantTwoIsOpen}
    >
      <InfoPanel
        productName={productName}
        storePrice={storePrice}
        originalPrice={originalPrice}
        ratingAverage={ratingAverage}
        ratingCount={ratingCount}
        reviewsRef={reviewsRef}
        showReviews={showReviews}
        isRecyclable={isRecyclable}
      />
      <Colors
        productRef={productRef}
        objectID={objectID}
        colorRef={colorRef}
        detailedColorLabel={detailedColorLabel}
        onColorClick={onColorClick}
        colorVariants={activeColors}
      />
      <button
        type="button"
        className="size-button-for-variant-two"
        data-testid="size-button-for-variant-two"
        id="size-button-for-variant-two"
        onClick={() => handleCustomSizeBlockToggle(!customSizeBlockForVariantTwoIsOpen)}
      >
        <p>
          {`${sku ? LBL_SIZE_SELECTED : ERR_CHOOSE_SIZE} : `}
          {sku && <span className="size-label">{getSizeLabel(size)}</span>}
        </p>
        <Icon name="chevronDown" size={16} />
      </button>
      <div ref={refVariantTwoBlock} className="size-block-variant-two">
        <Sizes
          objectID={objectID}
          sizeMessage={sizeMessage}
          sortedSizes={sortedSizes}
          setHoveredSku={setHoveredSku}
          isNoLongerAvailable={isNoLongerAvailable}
          errMsg={sizeErrMsg}
          selectedSku={size.sku}
          setSelectedSku={onSizeClick}
          handleCustomSizeBlockToggle={handleCustomSizeBlockToggle}
          isVariantTwo
        />
        <div className="button-block">
          <DynamicButton
            id="btn-add-to-cart-modal"
            data-testid="btn-add-to-cart-modal"
            onClick={handleCtaClick}
            data={CTA_CONTENT(true)}
            feedback={ctaState}
          />
        </div>
      </div>
    </StyledContainer>
  );
};
