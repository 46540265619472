import React from 'react';
import Transition from 'react-transition-group/Transition';

import { Box } from '../../../design-system';

import { QuickAdd } from './QuickAdd';
import { Background, transitionStylesBackground } from '../../common/components/Sidebar';
import { zIndex, transitions, heights, durations } from '../../common/constants';

export const transitionStylesContent = {
  entering: { bottom: `-${heights.QUICK_ADD_MOBILE}px` },
  entered: { bottom: 0 },
  exiting: { bottom: `-${heights.QUICK_ADD_MOBILE}px` },
  exited: { bottom: `-${heights.QUICK_ADD_MOBILE}px` },
};

type Props = {
  productRef: string;
  colorRef: string;
  originalSelectedColor?: string;
  isActive: boolean;
  onClose: () => void;
};

export const QuickAddMobile = ({
  productRef,
  colorRef,
  isActive,
  originalSelectedColor,
  onClose,
}: Props) => (
  <>
    <Transition
      in={isActive}
      mountOnEnter
      unmountOnExit
      timeout={{ enter: 0, exit: durations.EXIT }}
    >
      {(state) => (
        <Background
          onClick={onClose}
          style={{
            ...transitionStylesBackground[state],
          }}
        />
      )}
    </Transition>
    <Transition
      in={isActive}
      mountOnEnter
      unmountOnExit
      timeout={{ enter: 0, exit: durations.EXIT }}
    >
      {(state) => (
        <Box
          bg="WHITE"
          width="100%"
          position="fixed"
          left={0}
          maxHeight={`${heights.QUICK_ADD_MOBILE}px`}
          zIndex={zIndex.MODAL - 1}
          transition={transitions.GENERIC}
          style={{
            ...transitionStylesContent[state],
          }}
        >
          <QuickAdd
            productRef={productRef}
            colorRef={colorRef}
            onClose={onClose}
            originalSelectedColor={originalSelectedColor}
          />
        </Box>
      )}
    </Transition>
  </>
);
